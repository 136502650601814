import React, { useState, useRef } from "react";
import emailjs, { send } from "emailjs-com";

const CaaxPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef(); // Reference to the form

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Access service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Access template ID
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Access public key
      )
      .then(
        (result) => {
          alert("Form submitted successfully!");
          toggleModal();
        },
        (error) => {
          alert("Failed to send form. Please try again.");
        }
      );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 py-16">
      {/* Main Content Container */}
      <div className="relative flex flex-col items-center justify-center md:flex-row">
        {/* Centered Video Container */}
        <div className="relative mx-auto mb-8 md:mb-0 w-full max-w-[787px] h-[487px] bg-[#050B15] rounded-[30px] border border-[#0E1F36] shadow-lg flex items-center justify-center overflow-hidden">
          <video
            src="https://firebasestorage.googleapis.com/v0/b/xbosonai-caeb0.appspot.com/o/caaxv.mp4?alt=media&token=b6d3e20a-7820-40ac-95b1-782a94040a0f"
            autoPlay
            loop
            muted
            className="w-full h-full object-cover rounded-[30px]"
          />
        </div>

        {/* Right Text and Button Section */}
        <div className="relative flex flex-col text-center text-white md:text-left md:ml-12 md:items-start">
          {/* CAAX Image Positioned Above the Text */}
          <img
            src="/assets/caaxl.svg"
            alt="CAAX Logo"
            className="mb-6 w-36 md:w-44 lg:w-48" // Added margin-bottom to give space between the logo and text
          />

          {/* Text Container */}
          <div className="mt-4 md:mt-12 lg:mt-16">
            <h1 className="text-4xl font-bold text-white">
              Experience the{" "}
              <span
                style={{
                  background:
                    "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Digital Growing
              </span>
            </h1>
          </div>

          <div className="relative inline-block mt-8">
            <button
              className="px-8 py-3 mt-10 bg-[#E3E4E5] text-xl font-semibold rounded-full hover:shadow-lg transition-all duration-300"
              onClick={toggleModal}
            >
              <span
                style={{
                  background:
                    "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Own it Now
              </span>
            </button>
          </div>
        </div>
      </div>

      {/* Content Above Description */}
      <div className="px-4 mt-12 text-center text-white md:text-left">
        <h2 className="mb-4 text-3xl font-bold md:text-4xl">
          Introducing{" "}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            CAAX
          </span>
          : The{" "}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Personal Food Computer
          </span>{" "}
          Revolutionizing Indoor Cultivation
        </h2>
        <p className="max-w-3xl mx-auto text-lg leading-relaxed md:text-xl">
          Experience the future of sustainable farming with{" "}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            CAAX
          </span>{" "}
          — grow fresh, delicious greens at home using minimal resources. With
          patented technology and groundbreaking efficiency, CAAX redefines
          indoor agriculture.
        </p>
      </div>

      {/* Conditional Modal Rendering */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-gray-800 rounded-lg shadow-lg">
            <h2 className="mb-4 text-2xl font-bold text-center text-white">
              Connect With Us
            </h2>

            <form ref={form} onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your name"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Number
                </label>
                <input
                  type="text"
                  name="number"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Message
                </label>
                <textarea
                  name="message"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded-lg hover:bg-red-700"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  value={send}
                  className="px-4 py-2 text-sm font-semibold text-white rounded-lg"
                  style={{
                    background:
                      "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* New Section with Key Points */}
      <div className="max-w-4xl px-4 mx-auto mt-10 text-left md:flex md:space-x-10">
  <div className="mb-10 md:mb-0 md:w-1/2">
    <h3 className="mb-4 text-2xl font-bold text-white">Why Choose CAAX?</h3>
    <ul className="space-y-4">
      <li className="text-lg text-white">
        <div className="flex items-start">
          🚀 <strong className="ml-2">Patented Technology (Intellectual Property) :</strong>
        </div>
        <div className="pl-8">
          Advanced algorithms for unmatched resource optimization.
        </div>
      </li>
      <li className="text-lg text-white">
        <div className="flex items-start">
          💧 <strong className="ml-2">Water Conservation Champion :</strong>
        </div>
        <div className="pl-8">
          Grow 110g of lettuce with just 230ml of water — 90% less than traditional methods!
        </div>
      </li>
      <li className="text-lg text-white">
        <div className="flex items-start">
          ⚡ <strong className="ml-2">Energy Efficiency Master :</strong>
        </div>
        <div className="pl-8">
          Cultivate greens with a mere 0.03 KWh/month — ultra-low energy usage.
        </div>
      </li>
    </ul>
  </div>

  <div className="md:w-1/2">
    <h3 className="mb-4 text-2xl font-bold text-white">What This Means for You:</h3>
    <ul className="space-y-4">
      <li className="text-lg text-white">
        <div className="flex items-start">
          🌱 <strong className="ml-2">Grow More Food :</strong>
        </div>
        <div className="pl-8">
          Produce more using less — maximize yield with minimal resources.
        </div>
      </li>
      <li className="text-lg text-white">
        <div className="flex items-start">
          💸 <strong className="ml-2">Reduce Costs :</strong>
        </div>
        <div className="pl-8">
          Cut down on water and electricity expenses without sacrificing crop quality.
        </div>
      </li>
      <li className="text-lg text-white">
        <div className="flex items-start">
          🔒 <strong className="ml-2">Secure Supply :</strong>
        </div>
        <div className="pl-8">
          Maintain a steady food supply, even in uncertain times.
        </div>
      </li>
    </ul>
  </div>
</div>
<div className="-mt-20 ml-[-10%] md:ml-[-190px]"> 
  <img
    src="/assets/desc.svg"
    alt="Description of the content"
    className="w-full max-w-[1000px] mx-auto" 
  />
</div>


    </div>
  );
};

export default CaaxPage;
